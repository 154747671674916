import React from "react"
import { graphql, navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"

import { GiLightBulb, GiPerson } from "react-icons/gi"
import { FcPodiumWithSpeaker, FcEngineering } from "react-icons/fc"
import { FaDollarSign, FaHandsHelping } from "react-icons/fa"
import { AiFillStar } from "react-icons/ai"
import { BsPencil } from "react-icons/bs"

import { useBooks } from "../hooks/use-books"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

const personTagIcons = {
  entrepreneur: <GiLightBulb size={20} />,
  executive: <GiPerson size={20} />,
  politician: <FcPodiumWithSpeaker size={20} />,
  author: <BsPencil size={20} />,
  "venture capitalist": <FaDollarSign size={20} />,
  "media personality": <AiFillStar size={20} />,
  philanthropist: <FaHandsHelping size={20} />,
  engineer: <FcEngineering size={20} />
}

export default function Person({ data }) {
  const person = data.postgres.person
  const books = useBooks()

  const getBookWithAlias = (books, alias) => {
    return books.filter(book => book.alias === alias)[0]
  }

  const handleBookClick = alias => {
    navigate("/books/" + alias, { state: { alias: alias } })
  }

  const recommendationsLength =
    person.recommendations == null ? 0 : person.recommendations.length
  const recommendationsSEOtext = recommendationsLength <= 1 ? "book" : "books"

  return (
    <>
      <SEO
        title={
          person.name +
          " recommends " +
          recommendationsLength +
          " " +
          recommendationsSEOtext
        }
        description={
          person.name +
          " recommends " +
          recommendationsLength +
          " " +
          recommendationsSEOtext
        }
      />
      <Header />
      <div
        style={{
          marginTop: "75px",
          paddingBottom: "25px",
          backgroundColor: "whitesmoke"
        }}
      >
        <Container style={{ paddingTop: 16 }}>
          <Grid container spacing={4}>
            <Grid
              container
              item
              xs={12}
              sm={5}
              md={3}
              lg={3}
              xl={3}
              style={{
                marginBottom: "20px",
                marginTop: "10px"
              }}
            >
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "75%",
                  height: "auto"
                }}
                src={require("../images/persons/" + person.alias + ".jpg")}
                alt={person.name}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={7}
              md={9}
              lg={9}
              xl={9}
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                marginBottom: "20px",
                alignContent: "flex-start",
                flexDirection: "column"
              }}
            >
              <Typography variant="h2">{person.name}</Typography>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {person.tags.map(tag => (
                  <Chip
                    style={{ margin: "4px 4px 4px 0px" }}
                    label={tag}
                    icon={personTagIcons[tag]}
                    variant={"default"}
                    color="primary"
                  />
                ))}
              </div>
              <Typography variant="body2" color="textSecondary">
                {person.description}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            {person.recommendations.length} book recommendations
          </Typography>
          <Grid container spacing={4}>
            {person.recommendations.map(
              alias =>
                books.length !== 0 && (
                  <Grid
                    item
                    key={alias}
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                    style={{ display: "flex" }}
                  >
                    <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                      <CardActionArea onClick={() => handleBookClick(alias)}>
                        <CardMedia
                          component="img"
                          src={require("../images/books/" + alias + ".jpg")}
                        />

                        <CardContent>
                          <Typography>
                            {getBookWithAlias(books, alias).title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Container>
        )
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($nodeId: ID!) {
    postgres {
      person(nodeId: $nodeId) {
        name
        description
        tags
        description
        recommendations
        alias
      }
    }
  }
`
